<template>
    <div>
      <b-modal
        id="modal-edit-specialist"
        size="lg"
        centered
        title="Edit Data Master Specialist"
        header-bg-variant="warning"
        header-text-variant="light"
        @hidden="tutupModal"
      >
      <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Specialist <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_specialist')"
              type="text"
              v-model="$v.is_data.nama_specialist.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Specialist <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('kode_specialist')"
              type="text"
              v-model="$v.is_data.kode_specialist.$model"
            ></b-form-input>
          </b-form-group>
        </b-form>
  
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit-specialist')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
          >
            {{ button }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </template>
  <script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  export default {
    name: "modalEdit",
    props: ["data_edit"],
    data() {
      return {
        busy: false,
        button: "Simpan",
        is_data: null,
      };
    },
    computed: {
      computed_val: {
        get() {
          return this.is_data;
        },
        set(newVal) {
          this.is_data = newVal;
        },
      },
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
    },
    watch: {
      data_edit(newVal) {
        this.is_data = newVal;
      },
    },
  
    mixins: [validationMixin],
    validations: {
        is_data: {
            nama_specialist: {
                required,
            },
            kode_specialist: {
                required,
            },
        },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        if (vm.isValid) {
          vm.busy = true;
          vm.button = "Mohon Tunggu";
          vm.$axios
            .post("/ms_specialist/update", vm.is_data)
            .then((res) => {
              if (res.data.message == "sukses") {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "success",
                  msg: "BERHASIL MENGUBAH DATA",
                  showing: true,
                });
                vm.$bvModal.hide("modal-edit-specialist");
                vm.resetModal();
              } else {
                vm.button = "Simpan";
                vm.busy = false;
                vm.$emit("alertFromChild", {
                  variant: "danger",
                  msg: (res.data.message).toUpperCase(),
                  showing: true,
                });
              }
            })
            .catch(() => {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: "TERJADI KESALAHAN PADA SERVER",
                showing: true,
              });
            });
        }
      },
      tutupModal() {
        this.$emit("tutupModal");
      },
      resetModal() {
        this.$v.$reset();
        this.is_data = {
          nama_specialist: "",
          kode_specialist: "",
        };
      },
    },
  };
  </script>
  